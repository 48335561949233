export interface Dimensions {
  [key: string]: any;
}

const getPageViewCustomDimensions = () => {
  let dimensions: Dimensions = {};
  if (window?.ecGaPageViewCustomDimensions) {
    dimensions = { ...window.ecGaPageViewCustomDimensions };
  }
  return dimensions;
};

export default getPageViewCustomDimensions;
