import getTCStringFromCookies from './getTCStringFromCookies';
import isClientSide from '../utils/isClientSide';

const updateCmpApiWithTCString = (tCString: string = getTCStringFromCookies()) => {
  if (isClientSide && window?.cmpApi) {
    window?.cmpApi.update(tCString);
    return true;
  }

  return false;
};

export default updateCmpApiWithTCString;
