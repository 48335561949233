/**
 * Validation for the eventObject
 */
function isValidEventObject(eventObject: unknown): eventObject is Record<string, unknown> {
  if (typeof eventObject === 'object' && eventObject !== null && !Array.isArray(eventObject)) {
    return true;
  }
  return false;
}

export default isValidEventObject;
