const CONFIG = {
  /**
   * This is our CMP ID with the IAB
   */
  CMP_ID: 308,
  /**
   * This is the version of the CMP
   */
  CMP_VERSION: 2,
  /**
   * This is the version of TCF to use
   */
  TCF_VERSION: 2,
  /**
   * Cookie name for TC String
   */
  TC_STRING_COOKIE_NAME: 'MpTCString',
  /**
   * Cookie expiry period in days
   */
  COOKIE_EXPIRY_PERIOD: 365,
  /**
   * This is the number of purposes there are to consent to
   */
  NUMBER_OF_PURPOSES: 10,
};

export default CONFIG;
