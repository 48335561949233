/* eslint-disable camelcase */
import getDataFromLayer from './dataLayer';
import { GA4Event } from './utils/types';
import { updateDataLayerObjectFromCustomDimensions } from './utils/mapper';
import { ensureExperimentsInDataLayer } from './utils/ensureExperimentsInDataLayer';

/**
 * GA4 dual implementation HZ-4337
 * These event names are external requirements
 */
function sendGA4EventData(eventData: Record<string, unknown>) {
  const eventName = eventData?.eventAction || eventData?.event;
  const eventLabel = eventData?.eventLabel || eventData?.label;

  if (!eventName || typeof eventName !== 'string') {
    return;
  }

  updateDataLayerObjectFromCustomDimensions(eventData);
  ensureExperimentsInDataLayer();

  window?.dataLayer?.push({
    event: 'ga4Event',
    event_name: eventName,
    ...(eventLabel ? { [eventName]: { event_label: eventLabel } } : {}),
    event_category: getDataFromLayer()?.pageType,
  } as GA4Event);
}

export default sendGA4EventData;
