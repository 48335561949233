import type { TCustomGlobalConfig, TPageViewMetricConfig } from '../../types/global';
// TODO think about creating a customGlobalConfigHelper lib so other clients can use this helper
// This will provide a standardised way of accessing global variables

class CustomGlobalConfig {
  private readonly config?: TCustomGlobalConfig = window.customGlobalConfig;

  getPageViewMetricConfig(): TPageViewMetricConfig & { isValidConfig: boolean } {
    const isValidConfig = [
      this.config?.pageViewMetricConfig?.vurl,
      this.config?.pageViewMetricConfig?.isPageViewCustomMetricEnabled,
      this.config?.pageViewMetricConfig?.gptBatchMetricsEndpointUrl,
    ].every(Boolean);

    return {
      isValidConfig,
      vurl: this.config?.pageViewMetricConfig?.vurl || '',
      isPageViewCustomMetricEnabled: this.config?.pageViewMetricConfig?.isPageViewCustomMetricEnabled || false,
      gptBatchMetricsEndpointUrl: this.config?.pageViewMetricConfig?.gptBatchMetricsEndpointUrl || '',
    };
  }
}

export default CustomGlobalConfig;
