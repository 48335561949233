/* eslint-disable no-undefined */
import merge from 'lodash.merge';
import { DataLayerData } from './utils/types';

function getDateLayer(): DataLayerData {
  const originalDataLayer = window.dataLayer ? window.dataLayer : [];
  let dataLayerObject = {} as DataLayerData; // eslint-disable-line prefer-const

  originalDataLayer.forEach((layer: any) => {
    merge(dataLayerObject, layer);
  });

  return {
    ...dataLayerObject,
    page: dataLayerObject.p,
    pageType: dataLayerObject.p ? dataLayerObject.p.t : undefined,
    pagePrivate: dataLayerObject.p ? dataLayerObject.p.pvt : undefined,
    category: dataLayerObject.c,
    location: dataLayerObject.l,
    search: dataLayerObject.s,
    user: dataLayerObject.u,
    hashedUserId: dataLayerObject.u ? dataLayerObject.u.huid : undefined,
    advert: dataLayerObject.a,
    device: dataLayerObject.d,
    reply: dataLayerObject.m,
    eCommerce: dataLayerObject.o,
    clickInfo: dataLayerObject.clickInfo,
  };
}

export function getDataLayerValueByPath(path: string): unknown {
  const dataLayer = getDateLayer();
  return path.split('.').reduce((acc, currentKey) => (acc === undefined ? acc : acc[currentKey]), dataLayer);
}

export const dataLayerEmptyValues = [undefined, null, '', -1, '-1', '(null)', 'null', 'undefined', '_', '_undefined'];

export default getDateLayer;
