import { isAllPurposesAccepted } from '@ecg-marktplaats/gdpr-consent-string';

/**
 * Allow the @ecg-marktplaats/sourcepoint-lib global API to override
 * the @ecg-marktplaats/gdpr-consent-string library's API
 *
 * @returns {boolean}
 */
const isConsentedToGoogle = (): boolean => {
  if (typeof window?._sp_lib?.getConsentState === 'function') {
    return window._sp_lib.getConsentState(document.cookie).Google;
  }

  return isAllPurposesAccepted();
};

export default isConsentedToGoogle;
