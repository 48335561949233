import getTCStringFromCookies from './getTCStringFromCookies';

/**
 * When the user accepts or declines we set a cookie value.
 * It is currently not possible to dismiss the banner without setting a cookie
 * value.
 *
 * This function will check if the cookie has been set in order
 * to determine if the banner has been dismissed
 *
 * @param tcStringOverride - used for server side rendering
 */
const hasBannerBeenDismissed = (tcStringOverride?: string) => Boolean(tcStringOverride || getTCStringFromCookies());

export default hasBannerBeenDismissed;
