import Cookies from 'js-cookie';
import CONFIG from '../constants/CONFIG';
import updateCmpApiWithTCString from './updateCmpApiWithTCString';
import isClientSide from '../utils/isClientSide';
import getDomains from '../utils/getDomains';

const removeTcStringCookieForDomain = (domain?: string) => {
  Cookies.remove(CONFIG.TC_STRING_COOKIE_NAME, {
    domain,
  });
};

/**
 * This function saves the TCString to the cookies
 */
const deleteTCStringCookie = () => {
  if (!isClientSide) {
    /**
     * Prevent deleting cookie for server side usage
     */
    return false;
  }

  const domains = getDomains(window?.location?.hostname);

  /**
   * Remove the cookie for the case it was set without any domain.
   */
  removeTcStringCookieForDomain();

  /**
   * Remove the cookie for the case it was set with the current domain
   * or one of the subdomains.
   */
  domains.forEach(removeTcStringCookieForDomain);

  updateCmpApiWithTCString();

  return true;
};

export default deleteTCStringCookie;
