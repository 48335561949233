import { EncodingOptions, Purpose, TCModel, TCString, VersionOrVendorList } from '@iabtcf/core';
import GVL from './iab/GVL';
import { GVL as _GVL } from '@iabtcf/core';
import getTCStringFromCookies from './helpers/getTCStringFromCookies';
import CONFIG from './constants/CONFIG';
import CUSTOM_PURPOSES from './constants/CUSTOM_PURPOSES';
import GDPRConfig from './constants/GDPRConfig';
import deleteTCStringCookie from './helpers/deleteTCStringCookie';
import saveTCStringToCookies from './helpers/saveTCStringToCookies';
import updateCmpApiWithTCString from './helpers/updateCmpApiWithTCString';
import isMinGoogleConsentGranted from './helpers/ifMinGoogleConsentGranted';
import isClientSide from './utils/isClientSide';

class GdprConsentString {
  public tcModel: TCModel;
  public gvl = _GVL;
  public countryCode: string = '';

  constructor(versionOrVendorList?: VersionOrVendorList, customGvlBaseUrl: string = `${GDPRConfig.baseUrl}`) {
    if (customGvlBaseUrl !== '') {
      GVL.baseUrl = customGvlBaseUrl;
    }

    const gvl = new GVL(versionOrVendorList);
    this.tcModel = new TCModel(gvl);
    this.tcModel.isServiceSpecific = true;
  }

  /**
   * encodes the TCModel into a TCString
   *
   * @param options - for encoding options other than default
   * @return base64url encoded Transparency and Consent String
   */
  getTCString = (options?: EncodingOptions) => TCString.encode(this.tcModel, options);

  /**
   * Decodes a TCString into a TCModel
   *
   * @param encodedTCString - base64url encoded Transparency and
   * Consent String to decode - can also be a single or group of segments of
   * the string
   * @return Returns populated TCModel
   */
  decode = (encodedTCString?: string) => {
    if (!encodedTCString) {
      return encodedTCString;
    }

    return TCString.decode(encodedTCString, this.tcModel);
  };

  /**
   * Initiates the GdprConsentString for a country and language
   */
  init = async (locale: string = '') => {
    let [language, countryCode] = locale.split('-');
    // Tenant and locale in `nl-NL` format
    const [fallbackLanguage, fallbackCountryCode] = GDPRConfig.locale.split('-');

    if (!language) {
      language = fallbackLanguage;
    }

    if (!countryCode) {
      this.countryCode = fallbackCountryCode;
    } else {
      this.countryCode = countryCode;
    }

    try {
      await this.tcModel.gvl.readyPromise;

      if (!locale) {
        // Set the purpose consent language and load the text in
        await this.tcModel.gvl.changeLanguage(language);
      }

      // Setup Custom purposes
      this.setupCustomPurposes();

      // Rehydrate state
      this.getDecodedTCStringFromCookies();

      // Remove invalid TCStrings
      this.removeInvalidTCStrings();

      // Set the publisher details
      this.setPublisherDetails();

      updateCmpApiWithTCString(this.getTCString());

      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return false;
    }
  };

  /**
   * In some cases when rehydrating, if the user has an old string, it may
   * have old information, this ensures we always update it with the correct information
   */
  setPublisherDetails = (consentScreenId = 1) => {
    this.tcModel.consentScreen = consentScreenId;
    // Set country code
    this.tcModel.publisherCountryCode = this.countryCode;
    this.tcModel.cmpId = CONFIG.CMP_ID;
    this.tcModel.cmpVersion = CONFIG.CMP_VERSION;
  };

  /**
   * In case the older has an old cmpId we need to remove it from their machine
   */
  removeInvalidTCStrings = () => {
    if (this.tcModel.cmpId !== CONFIG.CMP_ID) {
      this.resetAllUserConsent();
    }
  };

  /**
   * This function gets an empty consent string
   */
  getEmptyConsentString = () => {
    if (this.tcModel.gvl.isReady) {
      this.tcModel.unsetAll();
      return this.getTCString();
    }

    return '';
  };

  /**
   * This function removes the stored TCString and the tcModel
   */
  resetAllUserConsent = () => {
    /**
     * Reset the tcModel
     */
    if (this.tcModel.gvl.isReady) {
      this.tcModel.unsetAllPurposeConsents();
      this.tcModel.unsetAllPurposeLegitimateInterests();
      this.tcModel.unsetAllVendorConsents();
      this.tcModel.unsetAllVendorLegitimateInterests();
      this.tcModel.publisherCustomConsents.empty();
      this.tcModel.created = new Date();
      this.tcModel.lastUpdated = new Date();

      /**
       * Remove the TCString from cookies
       */
      deleteTCStringCookie();

      return true;
    }

    return false;
  };

  /**
   * This function is used to get a TCString which has accepted all consent
   */
  acceptAllUserConsent = () => {
    const acceptAllUserConsentTCString = this.getAcceptedAllConsentString();

    // Save TCString to cookies
    if (isClientSide) {
      saveTCStringToCookies(acceptAllUserConsentTCString);
    }

    return acceptAllUserConsentTCString;
  };

  /**
   * This fuction sets up all the custom purposes
   */
  setupCustomPurposes = () => {
    this.tcModel.customPurposes = {
      [CUSTOM_PURPOSES.ADOBE_DMP.id]: CUSTOM_PURPOSES.ADOBE_DMP,
      [CUSTOM_PURPOSES.ADOBE_DMP_KBC.id]: CUSTOM_PURPOSES.ADOBE_DMP_KBC,
      [CUSTOM_PURPOSES.ADOBE_DMP_KBC_NV.id]: CUSTOM_PURPOSES.ADOBE_DMP_KBC_NV,
    } as { [key: string]: Purpose };
  };

  /**
   * Special helper to assert if the user has consented to google ads
   */
  hasConsentedToGoogle = () => isMinGoogleConsentGranted(this.tcModel);

  /**
   * Special helper to assert if the user has consented to Adobe Dmp
   */
  hasConsentedToAdobeDmp = () => this.tcModel.publisherCustomConsents.has(CUSTOM_PURPOSES.ADOBE_DMP.id);

  /**
   * This function gets an accepted all consent consent string
   */
  getAcceptedAllConsentString = () => {
    if (this.tcModel.gvl.isReady) {
      this.tcModel.setAllPurposeConsents();
      this.tcModel.setAllPurposeLegitimateInterests();
      this.tcModel.setAllVendorConsents();
      this.tcModel.setAllVendorLegitimateInterests();
      this.tcModel.publisherCustomConsents.set(CUSTOM_PURPOSES.ADOBE_DMP.id);
      this.tcModel.publisherCustomConsents.set(CUSTOM_PURPOSES.ADOBE_DMP_KBC.id);
      this.tcModel.publisherCustomConsents.set(CUSTOM_PURPOSES.ADOBE_DMP_KBC_NV.id);
      return this.getTCString();
    }

    return '';
  };

  /**
   * Retrieves the current TCString and decodes it into a TCModel
   */
  getDecodedTCStringFromCookies = () => {
    const encodedTCString = getTCStringFromCookies();

    if (!encodedTCString) {
      return false;
    }

    try {
      // eslint-disable-next-line
      const tcModel = this.tcModel instanceof TCModel ? this.tcModel : undefined;
      // We need to resurface the UI if there have been changes to the global vendor list
      return TCString.decode(encodedTCString, tcModel);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return false;
    }
  };

  /**
   * Checks if a TCString is valid
   */
  isTCStringValid = () => {
    const decodedTCString = this.getDecodedTCStringFromCookies();

    if (!decodedTCString) {
      return false;
    }

    return (decodedTCString.vendorListVersion as number) >= this.tcModel.gvl.vendorListVersion - 1;
  };

  /**
   * Use this function to set a purpose's legitimate interest flag to true or false
   * This is used when you want to allow the user to opt in or out via a toggle
   *
   * @param purposeId - the purpose id to set
   * @param state - true or false depending on if the user has opted out or not
   */
  setPurposeLegitimateInterestId = (purposeId: number, state: boolean = false) => {
    const vendors = Object.keys(this.getVendorsWithBothConsentAndLegIntPurpose(purposeId)).map((value) =>
      Number(value),
    );

    if (state) {
      this.tcModel.purposeLegitimateInterests.set(purposeId);
      this.tcModel.vendorLegitimateInterests.set(vendors);
    } else {
      this.tcModel.purposeLegitimateInterests.unset(purposeId);
      this.tcModel.vendorLegitimateInterests.unset(vendors);
    }
  };

  /**
   * Use this function to set a purpose's consent flag to true or false
   * This is used when you want to allow the user to opt in or out via a toggle
   *
   * @param purposeId - the purpose id to set
   * @param state - true or false depending on if the user has opted out or not
   */
  setPurposeConsentId = (purposeId: number, state: boolean = false) => {
    const vendors = Object.keys(this.getVendorsWithBothConsentAndLegIntPurpose(purposeId)).map((value) =>
      Number(value),
    );

    if (state) {
      this.tcModel.purposeConsents.set(purposeId);
      this.tcModel.vendorConsents.set(vendors);
    } else {
      this.tcModel.purposeConsents.unset(purposeId);
      this.tcModel.vendorConsents.unset(vendors);
    }
  };

  /**
   * Use this function to set a vendors's consent flag
   * This is used when you want to allow the user to opt in or out via a toggle
   *
   * @param vendorId - the vendor id to set the flag for
   * @param state - true or false depending on if the user has opted out or not
   */
  setVendorConsent = (vendorId: number, state: boolean = false) => {
    if (state) {
      this.tcModel.vendorConsents.set(vendorId);
    } else {
      this.tcModel.vendorConsents.unset(vendorId);
    }
  };

  /**
   * Use this function to set a vendors's legitimate interest flag
   * This is used when you want to allow the user to opt in or out via a toggle
   *
   * @param vendorId - the vendor id to set the flag for
   * @param state - true or false depending on if the user has opted out or not
   */
  setVendorLegitimateInterests = (vendorId: number, state: boolean = false) => {
    if (state) {
      this.tcModel.vendorLegitimateInterests.set(vendorId);
    } else {
      this.tcModel.vendorLegitimateInterests.unset(vendorId);
    }
  };

  /**
   * Use this function to set special feature opt ins
   * This is used when you want to allow the user to opt in or out via a toggle
   *
   * @param specialFeatureId - the special feature id to set the flag for
   * @param state - true or false depending on if the user has opted out or not
   */
  setSpecialFeatureOptIn = (specialFeatureId: number, state: boolean = false) => {
    if (state) {
      this.tcModel.specialFeatureOptins.set(specialFeatureId);
    } else {
      this.tcModel.specialFeatureOptins.unset(specialFeatureId);
    }
  };

  /**
   * Use this function to set publisher custom consents
   * This is used when you want to allow the user to opt in or out via a toggle
   *
   * @param customConsentPurposeId - the custom consent purpose id to set the flag for
   * @param state - true or false depending on if the user has opted out or not
   */
  setPublisherCustomConsent = (customConsentPurposeId: number, state: boolean = false) => {
    if (state) {
      this.tcModel.publisherCustomConsents.set(customConsentPurposeId);
    } else {
      this.tcModel.publisherCustomConsents.unset(customConsentPurposeId);
    }
  };

  /**
   * Retrieves a list of all vendors with both consent and legitimate interest
   * @param purposeId - the purpose id to set
   */
  getVendorsWithBothConsentAndLegIntPurpose = (purposeId: number) => {
    const vendorsWithConsentPurpose = this.tcModel.gvl.getVendorsWithConsentPurpose(purposeId);
    const vendorsWithLegIntPurpose = this.tcModel.gvl.getVendorsWithLegIntPurpose(purposeId);

    return {
      ...vendorsWithConsentPurpose,
      ...vendorsWithLegIntPurpose,
    };
  };

  /**
   * Gets all vendors that the user has not consented
   */
  getGdprVendorConsentFlags = () => {
    const vendors = [CUSTOM_PURPOSES.ADOBE_DMP_KBC.id, CUSTOM_PURPOSES.ADOBE_DMP_KBC_NV.id];

    return vendors
      .reduce<number[]>((vendorOptOutFlags, vendorId) => {
        if (this.tcModel.publisherCustomConsents.has(vendorId)) {
          vendorOptOutFlags.push(vendorId);
        }

        return vendorOptOutFlags;
      }, [])
      .join(',');
  };

  /**
   * Saves the current consent string to the cookies
   */
  saveConsentString = () => {
    if (isClientSide) {
      saveTCStringToCookies(this.getTCString());
    }
  };
}

export type TGdprConsentString = InstanceType<typeof GdprConsentString>;
export default GdprConsentString;
