import trackingCommand from './trackingCommand';

/**
 * Sets up the tracking library.
 */
const setupTrackingLib = (): void => {
  const { ecGa } = window;

  if (ecGa && ecGa.q) {
    ecGa.q.forEach((args: any[]) => {
      trackingCommand(args[0], args[1]);
    });
  }

  window.ecGa = trackingCommand;
};

export default setupTrackingLib;
