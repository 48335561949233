import CustomGlobalConfig from './helpers/CustomGlobalConfig';
import { MetricsClient } from '@ecg-marktplaats/ecg-js-metrics';

/**
 * Sets up Custom metrics for tracking page views.
 */
const setupCustomMetrics = (): void => {
  const customGlobalConfig = new CustomGlobalConfig();
  const pageViewMetricConfig = customGlobalConfig.getPageViewMetricConfig();

  if (pageViewMetricConfig.isValidConfig) {
    const metricsClient = new MetricsClient({
      endpointUrl: pageViewMetricConfig.gptBatchMetricsEndpointUrl,
      virtualUrl: pageViewMetricConfig.vurl,
    });
    metricsClient.addMetrics({ pageView: 1 });
  }
};

export default setupCustomMetrics;
