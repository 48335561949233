import trackEvent, { pushToDataLayer } from './trackEvent';
import trackPageView from './trackPageView';

import type { TG4EventDataParams } from 'types/TG4EventData';

function processEventsOnLoad() {
  if (Array.isArray(window.ecGaEventsOnLoad)) {
    window.ecGaEventsOnLoad.forEach((event) => {
      trackEvent(event);
    });
  }
}

export default function trackingCommand(command: 'init'): void;
export default function trackingCommand(command: 'trackPage'): void;
export default function trackingCommand(command: 'trackEvent', data?: TG4EventDataParams | string): void;
export default function trackingCommand(command: 'pushToDataLayer', data?: Record<string, unknown>): void;
export default function trackingCommand(
  command: 'init' | 'trackPage' | 'trackEvent' | 'pushToDataLayer' | 'setNamedDimensions',
  data?: unknown,
) {
  switch (command) {
    case 'init':
      trackPageView();
      processEventsOnLoad();
      break;
    case 'trackPage':
      trackPageView();
      break;
    case 'trackEvent':
      trackEvent(data as TG4EventDataParams);
      break;
    case 'pushToDataLayer':
      pushToDataLayer(data as Record<string, unknown>);
      break;
    default:
  }
}
