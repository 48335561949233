import GOOGLE_REQUIRED_PURPOSE from '../constants/GOOGLE_REQUIRED_PURPOSE';
import { TCModel } from '@iabtcf/core';

/**
 * Renaming values for convienience
 */
export const GOOGLE_VENDOR_LIST_ID = 755;
const {
  STORE_AND_OR_ACCESS_INFORMATION_ON_A_DEVICE: PURPOSE_1,
  SELECT_BASIC_ADS: PURPOSE_2,
  MEASURE_AD_PERFORMANCE: PURPOSE_7,
  APPLY_MARKET_RESEARCH_TO_GENERATE_AUDIENCE_INSIGHTS: PURPOSE_9,
  DEVELOP_AND_IMPROVE_PRODUCTS: PURPOSE_10,
} = GOOGLE_REQUIRED_PURPOSE;

/**
 * Checks if the minimum requirements to get google to serve ads
 * has been satisfied
 *
 * For more information see https://support.google.com/admanager/answer/9805023?hl=en
 *
 * @param tcModel
 * @return {boolean}
 */
const isMinGoogleConsentGranted = (tcModel: TCModel = {} as TCModel) => {
  const requirements = [
    tcModel?.vendorConsents?.has(GOOGLE_VENDOR_LIST_ID),
    tcModel?.vendorLegitimateInterests?.has(GOOGLE_VENDOR_LIST_ID),
    tcModel?.purposeConsents?.has(PURPOSE_1),
    tcModel?.purposeConsents?.has(PURPOSE_2) || tcModel?.purposeLegitimateInterests?.has(PURPOSE_2),
    tcModel?.purposeConsents?.has(PURPOSE_7) || tcModel?.purposeLegitimateInterests?.has(PURPOSE_7),
    tcModel?.purposeConsents?.has(PURPOSE_9) || tcModel?.purposeLegitimateInterests?.has(PURPOSE_9),
    tcModel?.purposeConsents?.has(PURPOSE_10) || tcModel?.purposeLegitimateInterests?.has(PURPOSE_10),
  ];

  return requirements.every(Boolean);
};

export default isMinGoogleConsentGranted;
