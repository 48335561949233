import getTCModelFromCookies from './getTCModelFromCookies';
import CONFIG from '../constants/CONFIG';

/**
 * Checks if legitimate interest and purpose has been granted
 */
const isAllPurposesAccepted = (tcStringOverride?: string) => {
  const tcModel = getTCModelFromCookies(tcStringOverride);

  if (tcModel) {
    const results: boolean[] = [];

    tcModel.purposeConsents.forEach((value, id) => {
      results.push(value || tcModel.purposeLegitimateInterests.has(id));
    });

    return results.length === CONFIG.NUMBER_OF_PURPOSES;
  }

  return false;
};

export default isAllPurposesAccepted;
