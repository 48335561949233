import { processLongString } from './ensureExperimentsInDataLayer';

const DEFAULT_MAX_LENGTH = 500;

/**
 * Processes the given event data to ensure that string values do not exceed the specified maximum length.
 * If a string value exceeds the maximum length, it is split into multiple shorter strings and stored
 * under new keys. The original key is set to `undefined`.
 *
 * @param eventData - The event data to process, represented as a record of key-value pairs.
 * @param MAX_LENGTH - The maximum allowed length for string values. Defaults to `DEFAULT_MAX_LENGTH`.
 * @returns The processed event data with long strings split into shorter strings.
 */
function processForShortExperiments(
  eventData: Record<string, unknown>,
  MAX_LENGTH = DEFAULT_MAX_LENGTH,
): Record<string, unknown> {
  for (const key in eventData) {
    const value = eventData[key];
    if (typeof value === 'string' && value?.length > MAX_LENGTH) {
      const newExperiments = processLongString(value); //{ experiments_0: '...', experiments_1: '...' }
      // do not overwrite existing event
      for (const experiment in newExperiments) {
        eventData[experiment] = newExperiments[experiment];
      }
      eventData[key] = undefined;
    }

    if (value && typeof value === 'object') {
      processForShortExperiments(value as Record<string, unknown>, MAX_LENGTH);
    }
  }
  return eventData;
}

export default processForShortExperiments;
