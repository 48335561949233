import isClientSide from './isClientSide';

/**
 * hostName should be window.location.hostname
 */
const getDomains = (hostName: string): string[] => {
  if (!isClientSide) {
    return [];
  }

  const urlParts = hostName.split('.');

  /**
   * The domains constant will be declared with an array
   * similar to the example below depending on the URL.
   * ["www.marktplaats.nl", "marktplaats.nl", "nl"]
   */
  return urlParts?.map((_, index) => urlParts.slice(index).join('.'));
};

export default getDomains;
