/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  getDataLayerPathByName,
  getDataLayerItemFromDimensionId,
  isDeprecatedDimension,
  MapperInfo,
} from './dataLayerMap';
import type { DataLayerData, NamedDimensions } from './types';
import { getDataLayerValueByPath, dataLayerEmptyValues } from '../dataLayer';

export function updateDataLayerObjectFromCustomDimensions(data: Record<string, any>): boolean {
  const customDimensions = Object.keys(data).reduce((acc, key) => {
    if (!key.startsWith('dimension')) return acc;
    const dimensionId = key.replace('dimension', '');
    if (isDeprecatedDimension(Number(dimensionId))) {
      return acc;
    }
    acc[dimensionId] = data[key];
    return acc;
  }, {});

  return updateDataLayerObject(customDimensions, (key) => {
    const dimensionId = Number(key);
    return getDataLayerItemFromDimensionId(dimensionId);
  });
}

export function updateDataLayerObjectFromNamedDimensions(userParams: Partial<NamedDimensions>) {
  return updateDataLayerObject(userParams, (key) => getDataLayerPathByName(key));
}

function updateDataLayerObject(
  params: Record<string, any>,
  getInfo: (key: string, value: any) => MapperInfo | undefined,
) {
  let missingData: DataLayerData | undefined;
  Object.keys(params).forEach((key) => {
    const itemInfo = getInfo(key, params[key]);
    if (!itemInfo || !itemInfo.dataLayerPath) return;

    const { dataLayerPath } = itemInfo;
    const value = parseValue(params[key], itemInfo);
    if (!shouldUpdateValue(dataLayerPath, value)) return;

    const maxLength = 500;
    if (typeof value === 'string' && value.length > maxLength) {
      // eslint-disable-next-line no-console
      console.warn(
        `DataLayer value for path ${dataLayerPath} is too long (${value.length} characters). It will be truncated to 500 characters.`,
      );
    }

    missingData = missingData || {};
    updateObjectValueByPath(missingData, dataLayerPath, value);
  });
  if (missingData) {
    window.dataLayer.push(missingData);
    return true;
  }
  return false;
}

function shouldUpdateValue(dataLayerPath: string, value: any): boolean {
  const currentValue: any = getDataLayerValueByPath(dataLayerPath);
  const bothEmpty = dataLayerEmptyValues.includes(currentValue) && dataLayerEmptyValues.includes(value);
  if (currentValue === value || bothEmpty) {
    // same value, no need to update
    return false;
  }

  if (typeof currentValue === 'number' && typeof value !== 'number') {
    const numberValue = parseFloat(value);
    if (isNaN(numberValue)) {
      // value is not a number, so let's just update it as we don't know what it is
      return true;
    } else if (currentValue === numberValue) {
      return false;
    }
  }

  if (typeof currentValue === 'boolean' && typeof value !== 'boolean') {
    const booleanValue = value === 'true' || value === '1';
    if (currentValue === booleanValue) {
      return false;
    }
  }

  return true;
}

function updateObjectValueByPath(missingData: any, dataLayerPath: string, dimensionValue: any) {
  const path = dataLayerPath.split('.');
  let current = missingData;
  try {
    for (let i = 0; i < path.length - 1; i += 1) {
      current[path[i]] = current[path[i]] || {};
      current = current[path[i]];
    }
    current[path[path.length - 1]] = dimensionValue;
  } catch (error) {
    console.error(error, `cannot update value for path ${dataLayerPath}`, missingData, current);
  }
}

function parseValue(value: any, itemInfo: MapperInfo) {
  if (!itemInfo.dataType || typeof value === itemInfo.dataType) {
    return value;
  }

  if (itemInfo.dataType === 'number' && typeof value === 'string') {
    const val = parseFloat(value);
    return isNaN(val) ? null : val;
  }

  return value;
}
