import getTCModelFromCookies from './getTCModelFromCookies';
import isMinGoogleConsentGranted from './ifMinGoogleConsentGranted';

/**
 * This function is used to check if we should
 * use personalised google ads or not, for TCF v2
 *
 * @param tcStringOverride - used for server side rendering
 */
const isGooglePurposeAccepted = (tcStringOverride?: string): boolean => {
  const tcModel = getTCModelFromCookies(tcStringOverride);

  if (!tcModel) {
    return false;
  }

  return isMinGoogleConsentGranted(tcModel);
};

export default isGooglePurposeAccepted;
