export const getGVLBaseUrl = function (): string {
  try {
    // webpack 5 upgrade: `process` is no longer injected for the client
    return (process.env.GVL_BASE_URL as string) || '';
  } catch {
    // intentionally ignored error
    return '';
  }
};

export default getGVLBaseUrl;
