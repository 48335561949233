import { GVL } from '@iabtcf/core';
import getGVLBaseUrl from './getGVLBaseUrl';

/**
 *  The IAB requires CMPs to host their own vendor-list.json files.
 *  This must be set before creating any instance of the GVL class.
 */
GVL.baseUrl = getGVLBaseUrl();

/**
 * This changes the location of the language files for the purposes
 * This must be set before creating any instance of the GVL class.
 */
GVL.languageFilename = 'purposes/purposes-[LANG].json';

export default GVL;
