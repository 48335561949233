import Cookies from 'js-cookie';
import CONFIG from '../constants/CONFIG';
import GDPRConfig from '../constants/GDPRConfig';

/**
 * This function gets the TCString from the cookies
 */
const getTCStringFromCookies = () =>
  Cookies.get(CONFIG.TC_STRING_COOKIE_NAME, {
    path: '/',
    domain: GDPRConfig.cookieDomain,
  }) || null;

export default getTCStringFromCookies;
