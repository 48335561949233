import getDataFromLayer from './dataLayer';
import isConsentedToGoogle from './isConsentedToGoogle';

// Update the dimensionMap to include a range of custom dimensions for experiments (labs)
// This is related to the GA4 move where there's a limit of 500 chars per property value.
// roughly 6000 chars now
// 20 * 500 = 10000 chars is a reasonable limit.

// start at 200 range to avoid overwriting existing dimensions
// GA4 limt is 100 but there are already others in use
// eslint-disable-next-line no-magic-numbers
export const getExperimentDimensionSlots = (startPosition = 200, propertiesLimit = 20) => {
  const dimensionMap = {};
  for (let i = 1; i <= propertiesLimit; i += 1) {
    dimensionMap[startPosition + i] = [`u.tg.experiments_${i}`];
  }
  return dimensionMap;
};

export default function () {
  const dl = getDataFromLayer();
  const data = {
    p: dl.page,
    c: dl.category,
    l: dl.location,
    s: dl.search,
    u: dl.user,
    a: dl.advert,
    d: dl.device,
    m: dl.reply,
    o: dl.eCommerce,
    clickInfo: dl.clickInfo,
  } as { [key: string]: any };

  // Perform any required data manipulations here:
  function getComplexValues() {
    // Set L1 Cat = L0 cat if it's not been defined
    if (data.c) {
      if (typeof data.c.l1 === 'undefined' && data.c.l0) {
        data.c.l1 = data.c.l0;
      }
    }

    // Dimension14 - platform version
    if (data.p) {
      // eslint-disable-next-line camelcase
      data.p.pl_v = data.p.pl || '';
      if (data.p.v) {
        // eslint-disable-next-line camelcase
        data.p.pl_v += ` (${data.p.v})`;
      }

      // Dimension17 - Get page config
      if (data.p.cfg) {
        let cfgStr = '';

        Object.keys(data.p.cfg).forEach((key) => {
          cfgStr += `${key}=${data.p.cfg[key]};`;
        });

        data.p.cfgStr = cfgStr;
      }
    } else {
      data.p = {};
    }

    //      // dimension65, 66 & 67 - window orientation, width & height
    //      if (window.innerWidth && window.innerHeight) {
    //      data.p.ww = window.innerWidth;
    //      data.p.wh = window.innerHeight;
    //      if (window.innerWidth > window.innerHeight) {
    //      data.p.wo = 'L';
    //      } else {
    //      data.p.wo = 'P';
    //      }
    //      }

    // Dimension 37 - reply type
    if (data.m) {
      const t = data.m.t || '';
      const dr = data.m.dr || '';
      data.m.rt = `${t}_${dr}`;
    }

    if (data.s) {
      // Dimension47 - onsite search sort type
      if (data.s.srt) {
        const f = data.s.srt.f || '';
        const dr = data.s.srt.dr || '';
        data.s.srt.t = [f, dr].join('_');
      }

      // Dimension104 - search attribute count
      const { attr } = data.s;
      if (attr) {
        let attrCount = 0;

        Object.keys(attr).forEach(() => {
          attrCount += 1;
        });

        data.s.attrCount = attrCount;
      }
    }

    // Dimension132 & 133 - Get data from local storage
    const localStorageData = localStorage.getItem('hzUlabels');
    if (localStorageData) {
      data.userLabels = localStorageData.split(':');
    }

    // Dimension147 - Stringify Click Info object
    if (data.clickInfo) {
      data.clickInfoStr = JSON.stringify(data.clickInfo);
    }

    // Dimension64 - connection type
    //      if(typeof data.d === 'undefined'){
    //      data.d = {};
    //      }
    //      var connection = navigator.connection ||
    //        navigator.mozConnection ||
    //        navigator.webkitConnection;
    //      if(connection){
    //        data.d.ct = connection.type;
    //      }

    // Dimension123 - dagtopper needed for BNL839 experiment
    if (data.a && data.a.id) {
      let isDagTopper = false;
      if (Array.isArray(data.a.ftr)) {
        isDagTopper = data.a.ftr.some((feat) => ['DAG_TOPPER', 'DAG_TOPPER_3DAYS', 'DAG_TOPPER_7DAYS'].includes(feat));
      }
      data.a.feats = `{"dagtopper":"${isDagTopper}"}`;
    }
  }
  getComplexValues();

  // Format:
  // Array[keyToUse, defaultValue] - Leave defaultValue undefined if you
  // dont want to set val in case of null.
  // Scalar values - These just get set directly and don't try to be evaluated.
  const dimensionMap = {
    1: ['p.t', '(NULL)'],
    2: ['c.l1.id'],
    3: ['c.l2.id'],
    4: ['c.l3.id'],
    5: ['c.l4.id'],
    6: ['l.l1.id'],
    7: ['l.l2.id'],
    8: ['l.l3.id'],
    9: ['l.l4.id'],
    10: ['c.c.id', -1],
    11: ['c.c.n'],
    12: ['l.c.id', -1],
    13: ['l.c.n'],
    14: ['p.pl_v'],
    15: ['p.lng'],
    16: ['p.vurl'],
    17: ['p.cfgStr'],

    19: ['u.lip'],
    20: ['u.huid'],
    21: ['u.hue'],
    22: ['u.haid'],
    23: ['u.li'], // Changed from 24 to match Global apps implementation
    24: ['u.at'], // Changed from 23 to match Global apps implementation
    25: ['u.tg.stg'],
    27: ['u.ss'],

    28: ['u.tg.ptg'],

    30: ['a.id'],
    31: ['a.prc.amt'],
    32: ['a.prc.t'],
    33: ['a.ic'],
    34: ['a.t'],
    35: ['a.lpdt'],
    36: ['a.cdt'],
    37: ['m.rt'],
    38: ['a.dl'],
    39: ['a.u.at'],
    40: ['s.kw'],
    41: ['s.pn'],
    42: ['s.ps'],
    43: ['s.tr'],
    44: ['l.d'],
    45: ['l.pcid'],
    46: ['l.ltlng'],
    47: ['s.srt.t'],
    48: ['p.vt'],
    49: ['a.acs'],

    //          65: ['p.wo'],
    //          66: ['p.ww'],
    //          67: ['p.wh'],
    68: ['d.ua'],
    69: ['d.ck'],
    70: ['p.ly'],
    71: ['d.s_ck'],
    74: ['d.gtm'],

    86: ['o.id'],
    88: ['o.py.pp'],

    90: ['c.l1.n'],
    91: ['c.l2.n'],

    100: ['s.prc.mn'],
    101: ['s.prc.mx'],

    104: ['s.attrCount'],
    105: ['s.t'],
    107: ['a.attr.cntcts'],
    108: ['a.attr'],
    109: ['s.attr'],
    110: ['a.attr.shippingPartner'],
    111: ['a.attr.ShippingMethod'],
    112: ['s.cid'],
    123: ['a.feats'],
    125: ['u.lar'],
    126: ['u.lap'],

    127: ['a.u.dp'], // Cars packages specific custom dimension (team placing)

    132: ['userLabels.1'],
    133: ['userLabels.2'],

    135: ['a.not.id'], // Local Marktplaats capturing the notification ID
    136: ['a.not.count'], // Local Marktplaats capturing the notification count

    142: ['a.u.scr'],
    143: ['a.u.rc'],
    144: ['m.cid'],
    145: ['p.ppt'],
    146: ['a.u.huid'],
    147: ['clickInfoStr'],
    148: ['s.ssid'],
    167: ['s.dc'],
    188: ['gdprConsent', `TCF 2: ${isConsentedToGoogle() ? 'Consent Given' : 'No Consent Given'}`],
    ...getExperimentDimensionSlots(),
  };

  /**
   * Takes in an object path. Returns the value found, Or the default value.
   *
   * e.g.
   * > data = {a: {b: 'c'})
   * > retrieveDataValue('a.b')
   * >> 'c'
   * > retrieveDataValue('d')
   * >> undefined
   * > retrieveDataValue('d', 'defaultValueOfSorts')
   * >> 'defaultValueOfSorts'
   */
  function retrieveDataValue(key: string, defaultVal: string) {
    const splitKey = key.split('.');
    let context = data;

    for (let i = 0; i < splitKey.length; i += 1) {
      context = context[splitKey[i]];

      if (typeof context === 'undefined') {
        return defaultVal;
      }
    }

    return context;
  }

  function applyDimensions() {
    const customDimensions = {};

    Object.keys(dimensionMap).forEach((idx) => {
      let val = dimensionMap[idx];

      if (typeof val === 'object') {
        val = retrieveDataValue(val[0], val[1]);
      }

      if (typeof val !== 'undefined') {
        switch (typeof val) {
          case 'object':
            val = JSON.stringify(val);
            break;
          case 'number':
            val = val.toString();
            break;
          case 'boolean':
            val = val ? '1' : '0';
            break;
          default:
            break;
        }

        if (val !== '') {
          customDimensions[`dimension${idx}`] = val;
        }
      }
    });

    return customDimensions;
  }

  // Apply Standard Dimensions
  return applyDimensions();
}
