import isValidEventObject from './isValidEventObject';
import sendGA4EventData from './trackGA4Event';

type EventObjectBase = {
  event?: string;
  label?: string;
  eventAction?: string;
  eventLabel?: string;
  eventDataLayer?: Record<string, unknown>;
};

type EventObject = EventObjectBase & {
  [key: string]: unknown;
};

/**
 * Pushes an event object to the data layer.
 * If the event object contains an 'eventDataLayer' property, it pushes the value of that property to the data layer and removes it from the event object.
 * If the event object does not have a valid 'eventAction' or 'event' property, it pushes the entire event object to the data layer.
 * Otherwise, it constructs a new event object with the necessary properties and pushes it to the data layer.
 * Finally, it sends the event data to Google Analytics 4.
 *
 * @param eventObject - The event object to be pushed to the data layer.
 */
export function pushToDataLayer(eventObject: EventObject) {
  if (!eventObject) {
    console.warn('No event object provided. Pushing to dataLayer aborted');
    return;
  }

  if ('eventDataLayer' in eventObject) {
    // event Data
    window.dataLayer.push(eventObject.eventDataLayer);
    delete eventObject.eventDataLayer;
  }

  const { eventAction, eventLabel, label, ...eventData } = eventObject;

  const eventName = eventAction || eventData.event;

  if (!eventName || typeof eventName !== 'string') {
    // event Data
    window.dataLayer.push(eventObject);
    return;
  }

  const event = {
    event: eventName,
    eventLabel: eventLabel || label,
    ...eventData,
  };

  // GA4 event
  sendGA4EventData(event);
}

export default function (eventObject: any) {
  // Send datalayer event for GTM to use as triggers
  if (!isValidEventObject(eventObject)) {
    throw new Error('Invalid event object provided. Pushing to dataLayer aborted');
  }

  pushToDataLayer(eventObject);
}
