import Cookies from 'js-cookie';
import CONFIG from '../constants/CONFIG';
import updateCmpApiWithTCString from './updateCmpApiWithTCString';
import GDPRConfig from '../constants/GDPRConfig';

/**
 * This function saves the TCString to the cookies
 */
const saveTCStringToCookies = (tCString: string) => {
  Cookies.set(CONFIG.TC_STRING_COOKIE_NAME, tCString, {
    path: '/',
    expires: CONFIG.COOKIE_EXPIRY_PERIOD,
    domain: GDPRConfig.cookieDomain,
  });

  updateCmpApiWithTCString(tCString);
};

export default saveTCStringToCookies;
