const LAST_TWO_ITEMS_OF_ARRAY = -2;

/**
 * Selects the apex domain
 *
 * @param hostname
 * @returns the root domain
 */
const getApexDomain = (hostname: string = ''): string => {
  const hostnameWithoutSchema = hostname.replace(/^http(s)?:\/\//, '');
  const parts = hostnameWithoutSchema.split('.');

  return parts.slice(LAST_TWO_ITEMS_OF_ARRAY).join('.');
};

export default getApexDomain;
