import { TCString } from '@iabtcf/core';
import getTCStringFromCookies from './getTCStringFromCookies';

/**
 * This function gets the tc model from cookies, but allows an optional
 * override by directly passing the TCString
 *
 * @param tcStringOverride - used for server side rendering
 */
const getTCModelFromCookies = (tcStringOverride?: string) => {
  try {
    const tcString = typeof tcStringOverride === 'string' ? tcStringOverride : getTCStringFromCookies();

    if (!tcString) {
      return null;
    }

    return TCString.decode(tcString);
  } catch (e) {
    return null;
  }
};

export default getTCModelFromCookies;
