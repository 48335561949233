/* eslint-disable camelcase */
import 'url-polyfill';
import setCustomDimensions from './setCustomDimensions';
import getPageViewCustomDimensions from './helpers/getPageViewCustomDimensions';
import { updateDataLayerObjectFromCustomDimensions } from './utils/mapper';
import { GA4PageView } from './utils/types';
import { ensureExperimentsInDataLayer } from './utils/ensureExperimentsInDataLayer';

const NULL_VALUE = '(NULL)';

function getPageCustomDimensions() {
  let paramString = '';
  const pageCustomDimensions = getPageViewCustomDimensions();
  const currentUrl = new URL(window.location.href);
  const utmSourceValue = currentUrl.searchParams.get('utm_source');

  function addToParamString(urlPart: string) {
    const array1 = urlPart.substring(1).split('&');
    for (let i = 0; i < array1.length; i += 1) {
      const UTM_PARAM = 'utm';
      const GCLID_PARAM = 'gclid';
      if (
        array1[i].substring(0, UTM_PARAM.length) === UTM_PARAM ||
        array1[i].substring(0, GCLID_PARAM.length) === GCLID_PARAM
      ) {
        paramString += `${array1[i]}&`;
      }
    }
  }

  function getReferrer() {
    let referrer: string;

    if (document.referrer === '') {
      referrer = utmSourceValue || NULL_VALUE;
    } else {
      const referrerDomain = new URL(document.referrer).hostname;
      const currentDomain = window.location.hostname;

      referrer = referrerDomain === currentDomain ? NULL_VALUE : referrerDomain;
    }

    return referrer;
  }

  pageCustomDimensions.dimension50 = getReferrer();

  // Check page query string for UTM & gclid parameters
  if (window.location.search !== '') {
    addToParamString(window.location.search);
  }

  // Check page anchors for UTM & gclid parameters
  if (window.location.hash !== '') {
    addToParamString(window.location.hash);
  }

  // Set UTM & gclid parameters as Dimension 53
  if (paramString.length > 0) {
    pageCustomDimensions.dimension53 = paramString;
  }

  return pageCustomDimensions;
}

export default function () {
  const pageCustomDimensions = getPageCustomDimensions();
  ensureExperimentsInDataLayer();
  const dimensions = setCustomDimensions();

  updateDataLayerObjectFromCustomDimensions({ ...dimensions, ...pageCustomDimensions });
  window.dataLayer.push({
    event: 'virtual_page_view',
    page_location: window.location.href,
    page_title: document.title,
  } as GA4PageView);
}
