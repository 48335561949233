import { getDataLayerValueByPath } from '../dataLayer';

// only do it once per page load
let experimentsCopied = false;
function getExperimentsValue(): string | undefined {
  if (experimentsCopied) return;
  experimentsCopied = true;
  const experimentsValue = getDataLayerValueByPath('u.tg.stg');
  if (typeof experimentsValue !== 'string') return;

  return experimentsValue.length > 0 ? experimentsValue : undefined;
}

const MAX_LENGTH = 500;
const EXPERIMENTS_KEY = 'experiments';

// eslint-disable-next-line import/prefer-default-export
export function ensureExperimentsInDataLayer() {
  const experimentsValue = getExperimentsValue();
  if (!experimentsValue) return;

  const dataLayerExperiments = {};
  let startIndex = 0;
  let endIndex = 0;
  let currentIndex = 0;

  while (startIndex < experimentsValue.length) {
    endIndex = startIndex + MAX_LENGTH;

    // Check if the endIndex is within the string length and the character at endIndex is not '|'.
    // If so, update endIndex to be the last index of '|' before the current endIndex.
    if (endIndex < experimentsValue.length && experimentsValue[endIndex] !== '|') {
      endIndex = experimentsValue.lastIndexOf('|', endIndex);
    }

    // If no "|" was found in the range, just cut at the maxLength
    if (endIndex === -1) {
      endIndex = experimentsValue.length;
    }

    dataLayerExperiments[`${EXPERIMENTS_KEY}_${currentIndex}`] = experimentsValue.substring(startIndex, endIndex);

    startIndex = endIndex + 1;
    currentIndex++;
  }

  window.dataLayer.push({ u: { tg: { ...dataLayerExperiments } } });
}
