import getTCModelFromCookies from './getTCModelFromCookies';
import CUSTOM_PURPOSES from '../constants/CUSTOM_PURPOSES';

/**
 * This function is used to check if we should
 * use adobe dmp or not, for TCF v2
 *
 * @param tcStringOverride - used for server side rendering
 */
const isAdobeDmpPurposeAccepted = (tcStringOverride?: string): boolean => {
  const tcModel = getTCModelFromCookies(tcStringOverride);

  if (!tcModel) {
    return false;
  }

  return tcModel.publisherCustomConsents.has(CUSTOM_PURPOSES.ADOBE_DMP.id);
};

export default isAdobeDmpPurposeAccepted;
