/**
 * Do not remove '.min' from 'proxy-polyfill/proxy.min' otherwise finding frontend will break
 * as they are using uglify to compile their code.
 */
import 'proxy-polyfill/proxy.min';
import CONFIG from './constants/CONFIG';
import CUSTOM_PURPOSES from './constants/CUSTOM_PURPOSES';
import getEmptyConsentString from './helpers/getEmptyConsentString';
import isAllPurposesAccepted from './helpers/isAllPurposesAccepted';
import hasBannerBeenDismissed from './helpers/hasBannerBeenDismissed';
import GDPRConfig from './constants/GDPRConfig';
import GdprConsentString from './GdprConsentString';
import getTCStringFromCookies from './helpers/getTCStringFromCookies';
import updateCmpApiWithTCString from './helpers/updateCmpApiWithTCString';
import isGooglePurposeAccepted from './helpers/isGooglePurposeAccepted';
import isAdobeDmpPurposeAccepted from './helpers/isAdobeDmpPurposeAccepted';
import isClientSide from './utils/isClientSide';
import { getGVLBaseUrl } from './iab/getGVLBaseUrl';

/**
 * The marketing pages can't work with modules
 * So we have to expose the functions on the window object.
 */
if (isClientSide) {
  window['gdprConsentString'] = {
    isGooglePurposeAccepted,
    isAdobeDmpPurposeAccepted,
    isAllPurposesAccepted,
  };
}

export {
  CUSTOM_PURPOSES,
  CONFIG,
  GDPRConfig,
  GdprConsentString,
  updateCmpApiWithTCString,
  getTCStringFromCookies,
  getEmptyConsentString,
  hasBannerBeenDismissed,
  isAllPurposesAccepted,
  isGooglePurposeAccepted,
  isAdobeDmpPurposeAccepted,
  getGVLBaseUrl,
};
