import getApexDomain from '../helpers/getApexDomain';
import isClientSide from '../utils/isClientSide';

export type TGDPRConfig = {
  baseUrl: string;
  consentedToAllPurposes?: boolean;
  cookieDomain?: string;
  experiments: { [key: string]: string };
  implicitAcceptSelectors: string;
  locale: string;
  requestId?: string;
  userLoggedIn?: boolean;
  xsrfToken?: string;
};

const GDPRConfig: TGDPRConfig = new Proxy(
  {
    /**
     * This is the url for the location of the JSON files for the GVL (Global Vendor List)
     * and Purpose translations provided by the IAB, that are hosted by us.
     */
    baseUrl: '',
    locale: 'nl-NL',
    xsrfToken: 'invalidToken',
    experiments: {},
    userLoggedIn: false,
    cookieDomain: '.marktplaats.nl',
    implicitAcceptSelectors: 'a, img, select, textarea, input, button, span, a *',
  },
  {
    get(target, name, receiver) {
      if (isClientSide && name === 'cookieDomain') {
        return getApexDomain(window?.location?.hostname);
      }

      const mergedGDPRConfig = {
        ...target,
        ...((isClientSide && window.GDPRConfig) || {}),
      };

      return Reflect.get(mergedGDPRConfig, name, receiver);
    },
  },
);

export default GDPRConfig;
