import { GVLMapItem } from '@iabtcf/core';

export type TExtendedPurpose = {
  policyUrl?: string;
  description?: string;
  descriptionLegal?: string;
} & GVLMapItem;

type TCustomPurposes = {
  ADOBE_DMP: TExtendedPurpose;
  ADOBE_DMP_KBC: TExtendedPurpose;
  ADOBE_DMP_KBC_NV: TExtendedPurpose;
};

const CUSTOM_PURPOSES: TCustomPurposes = {
  ADOBE_DMP: {
    id: 1,
    name: 'Matching via Adobe',
    description:
      'Wij stellen segmenten samen met behulp van gepseudonimiseerde gebruikersgegevens en bieden adverteerders via onze partner <a href="https://www.adobe.com/cis_en/privacy/experience-cloud.html" target="_blank">Adobe</a>',
    descriptionLegal:
      'Wij stellen segmenten samen met behulp van gepseudonimiseerde gebruikersgegevens en bieden adverteerders via onze partner <a href="https://www.adobe.com/cis_en/privacy/experience-cloud.html" target="_blank">Adobe</a> een <i>data matching service</i> aan om hun reclame te personaliseren.\n\nGebruikerssegmenten kunnen bijvoorbeeld bestaan uit gebruikers die binnen een bepaalde periode naar een bepaald model voertuig hebben gezocht. Als een adverteerder geïnteresseerd is in dit gebruikerssegment, kan hij via onze partners een proces van gegevensmatching uitvoeren.\n\nAls onderdeel van het datamatching-proces vergelijken adverteerders hun eigen gegevens met onze segmenten om personen te vinden die ze al kennen (zogenaamde "matching"). Er worden geen gebruikersgegevens verstrekt aan adverteerders. Adverteerders kunnen deze gegevens vervolgens gebruiken om gepersonaliseerde - d.w.z. op interesse gebaseerde - reclame te tonen.\n\nDe gegevensmatching vindt uitsluitend plaats in gepseudonimiseerde vorm, d.w.z. met behulp van een individuele identificator zoals een cookie-ID. Persoonsgegevens die niet zijn gepseudonimiseerd (zoals naam of e-mailadres) worden niet gebruikt voor matching en worden op geen enkel moment aan adverteerders verstrekt.',
  },
  ADOBE_DMP_KBC: {
    id: 2,
    name: 'KBC Bank NV',
    policyUrl: 'https://www.kbc.be/particulieren/nl/juridische-info/privacyverklaring.html',
  },
  ADOBE_DMP_KBC_NV: {
    id: 3,
    name: 'KBC Verzekeringen NV',
    policyUrl: 'https://www.kbc.be/particulieren/nl/juridische-info/privacyverklaring.html',
  },
};

export default CUSTOM_PURPOSES;
